// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-portfolio-portfolio-clicked-portfolio-clicked-jsx": () => import("./../../../src/components/Portfolio/PortfolioClicked/PortfolioClicked.jsx" /* webpackChunkName: "component---src-components-portfolio-portfolio-clicked-portfolio-clicked-jsx" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dataprocessing-js": () => import("./../../../src/pages/dataprocessing.js" /* webpackChunkName: "component---src-pages-dataprocessing-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-government-js": () => import("./../../../src/pages/government.js" /* webpackChunkName: "component---src-pages-government-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loading-js": () => import("./../../../src/pages/loading.js" /* webpackChunkName: "component---src-pages-loading-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

